import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Center, Text, Link } from '@chakra-ui/react';
import { Button as CustomButton } from '@chakra-ui/react';

import Button from 'src/legacy/Components/Forms/Button';

import { Env, Urls, PR_NUMBER } from 'src/appConstants';
import { authApi } from 'src/api';
import { useUserStore } from 'src/hooks/stores';
import { useInvoiceCountQuery } from 'src/hooks/queries/invoice';
import { useMeQuery } from 'src/hooks/queries/user';
import { useAuthModal } from 'src/modules/auth-modal';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';

import './styles.scss';
import { getProducerById } from 'src/api/producer';
import { BodyBluredOverlay } from 'src/components/BodyBluredOverlay';

const postSignout = token => authApi.post('signout', { token });

const GithubPRLink = () => {
  if (Env !== 'staging' || !PR_NUMBER) {
    return null;
  }

  return (
    <Center fontSize="0.7rem" flexDirection="column" mt={2}>
      <Text>Ambiente de Homologação</Text>
      <Link
        href={`https://github.com/uticket/web/pull/${PR_NUMBER}`}
        isExternal>
        {`PR #${PR_NUMBER}`}
      </Link>
    </Center>
  );
};

const MainMenu = ({ show, onDismiss }) => {
  const userStore = useUserStore();
  const meQuery = useMeQuery();
  const invoiceCountQuery = useInvoiceCountQuery();
  const authModal = useAuthModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [userSlug, setUserSlug] = useState('');
  const onLogin = () => {
    authModal.onLogin();
    onDismiss();
  };

  const onSignup = () => {
    authModal.onSignup();
    onDismiss();
  };

  const onLogout = () => {
    const pathsToRedirect = ['/me', '/painel', '/financeiro'];

    const shouldRedirect = pathsToRedirect.some(path =>
      location.pathname.startsWith(path),
    );
    if (shouldRedirect) {
      navigate('/', { replace: true });
    }

    if (userStore.token) {
      postSignout(userStore.token);
    }

    userStore.clear();

    // queryClient.setQueryData(['me', userStore.email], null);
    // TODO: antes de limpar, realizar tratamento para verificar
    // se existem vendas que ainda não expiraram e fecha-las chamando a api
    window.localStorage.removeItem('uticket:checkout');
    onDismiss();
  };

  const onNavigateToLandingPage = () => {
    navigate('/solucoes');
  };

  const onCreateNewEvent = () => {
    navigate('/me/events/new');
  };

  // UPDATING TODO: ver o impacto disso e achar solução
  // history.listen(() => {
  //   onDismiss();
  // });

  useEffect(() => {
    const getSlug = async () => {
      try {
        const slug = await getProducerById(userStore.id);
        setUserSlug(slug.slug);
      } catch (err) {
        // console.log(err);
      }
    };
    if (meQuery.data?.organizer_page) getSlug();
  }, [meQuery.data?.organizer_page, userStore.id]);

  return (
    <>
      <BodyBluredOverlay handleCloseModal={onDismiss} isOpen={show} />

      <Box zIndex="999" className={`main-menu ${show && 'show'}`}>
        {meQuery.data?.email ? (
          <div className="part">
            <div className="logged menu-user-profile">
              <div className="inner-profile">
                <div
                  className="user-pic"
                  style={{
                    backgroundImage: `url(${Urls.IMG}/user/${meQuery.data?.id})`,
                  }}></div>
                <span data-testid="drawer-label-user" className="user-name">
                  {meQuery.data?.name}
                </span>
              </div>
            </div>
            <div className="items">
              <ul>
                <Box as="li" className="logged" onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    to="/me/tickets"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Meus Ingressos</Text>
                  </Link>
                </Box>
                <Box as="li" className="logged" onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    to="/me/account"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Minha Conta</Text>
                  </Link>
                </Box>

                <Box as="li" className="logged separator" onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    to="/me/events"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Meus Eventos</Text>
                  </Link>
                </Box>

                {meQuery.data.organizer_page ? (
                  <Box as="li" className="logged ">
                    <Box display={'flex'}>
                      <Link
                        as={RouterLink}
                        display="flex"
                        px="6"
                        py="2"
                        to={`/${userSlug}`}
                        onClick={onDismiss}
                        _focusWithin={{ boxShadow: 'none' }}>
                        <Text color="#000000">Perfil Organizador</Text>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                <Box as="li" className="logged" onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    gap="2"
                    alignItems="center"
                    to="/financeiro"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Financeiro</Text>
                    <NewFeatureBadge
                      mt=".5px"
                      createdAt={new Date(2023, 11, 20)}
                    />
                  </Link>
                </Box>
                <Box
                  as="li"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  className="logged"
                  onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    to="/painel/faturas"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Painel de controle</Text>
                  </Link>
                  {invoiceCountQuery.isSuccess &&
                    invoiceCountQuery.data > 0 && (
                      <Box bg="#f33e43" rounded="full" w="3" h="3" />
                    )}
                </Box>

                <Box as="li" className="logged separator " onClick={onDismiss}>
                  <Link
                    as={RouterLink}
                    display="flex"
                    px="6"
                    py="2"
                    to="/ajuda"
                    _focusWithin={{ boxShadow: 'none' }}>
                    <Text color="#000000">Central de ajuda</Text>
                  </Link>
                </Box>
                <Text
                  display="flex"
                  px="6"
                  py="2"
                  as="li"
                  className="logged logout"
                  onClick={onLogout}>
                  <Text color="#000000">Sair</Text>
                </Text>
              </ul>
            </div>
          </div>
        ) : (
          <div className="unlogged">
            <CustomButton
              fullWidth={true}
              rounded="full"
              onClick={onLogin}
              color="white"
              bgColor="#000000"
              _hover={{ bgColor: '#000000' }}
              mb={2}
              data-testid="drawer-login-button">
              Entrar
            </CustomButton>
            <CustomButton
              variant="outline"
              border="none"
              rounded="full"
              fullWidth={true}
              _hover={{ bgColor: 'white' }}
              onClick={onSignup}
              color="#000000">
              Cadastrar
            </CustomButton>
            <Button
              className="help-center-button"
              fullWidth={true}
              color="transparent">
              <Link
                as={RouterLink}
                display="flex"
                px="6"
                py="2"
                to="/ajuda"
                _focusWithin={{ boxShadow: 'none' }}>
                Central de ajuda
              </Link>
            </Button>
          </div>
        )}
        <Box mt="auto" px={3} pb={4}>
          <Button
            color="transparent"
            withBorder={false}
            onClick={onNavigateToLandingPage}
            className="organizers-page">
            Organizadores
          </Button>
          <Button onClick={onCreateNewEvent} className="publish-event">
            <Text color="#000000">Criar evento</Text>
          </Button>
          <div className="copyright">
            Copyright © {new Date().getFullYear()} todos os direitos
            reservados.
            <br />
            <a target="_blank" href="/terms">
              Termos de serviço
            </a>{' '}
            |{' '}
            <a target="_blank" href="/privacy">
              Política de privacidade
            </a>
          </div>
          <GithubPRLink />
        </Box>
      </Box>
    </>
  );
};

export default MainMenu;
